<template>
  <div id="terms-of-use">
    <Navbar />

    <div class="content">
      <h1 class="page-title">TERMS OF USE</h1>

      <div class="last-updated">Last Updated on July 6, 2020</div>

      <p dir="ltr">
        These Terms of Use are between you (as defined below) and Company (as
        defined below) concerning your use of (including any access to) the
        website(s), software application(s) and other online resource(s) of
        Company, including any successor resources thereto (the “Platform”). The
        term “Platform” includes any database associated with the Platform (a
        “Database”), any software provided by Company to facilitate use of any
        aspect of the Platform (“Associated Software”), and any graphic, logo,
        image, illustration, design, icon, digital download, data compilation,
        article, document, product, branding, function, layout, service or
        written or other material that is part of the Platform (“Materials”).
      </p>
      <br />
      <p dir="ltr">
        THE PLATFORM IS DIRECTED TO BUSINESS USERS. BY ACCESSING OR USING THE
        PLATFORM, OR OTHERWISE INDICATING YOUR ASSENT TO THESE TERMS OF USE
        (INCLUDING BY CLICKING ANY BUTTON LABELED WITH “AGREE” OR SIMILAR
        WORDING, YOU (BOTH FOR YOURSELF AND FOR ANY ENTITY ON WHOSE BEHALF YOU
        ARE ACTING), ARE (A) ACKNOWLEDGING AND ACCEPTING THE TERMS AND
        CONDITIONS OF THESE TERMS OF USE, AND ENTERING INTO AN AGREEMENT WITH
        COMPANY TO BE BOUND BY THESE TERMS OF USE, AND (B) REPRESENTING AND
        WARRANTING THAT YOU HAVE THE AUTHORITY TO AGREE TO THESE TERMS OF USE,
        INCLUDING ON BEHALF OF ANY SUCH REPRESENTED ENTITY, AND THAT YOU ARE 18
        YEARS OF AGE OR OLDER.
      </p>
      <br />
      <p dir="ltr">
        If you do not accept these Terms of Use, you must not access or use the
        Platform.
      </p>
      <br />
      <p dir="ltr">
        Definition of the Parties.
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            The term “Company” means The Harry Fox Agency LLC, located at 40
            Wall Street, 6th Floor, New York, NY 10005. You may contact the
            Company by mailing a letter to the preceding address or by
            submitting your inquiry via Company’s
            <a @click="showContactForm">Contact Form</a>.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The term “User” (or “you”) means the individual and/or entity
            accessing the Platform under these Terms of Use, including all
            related and administered companies or entities purported to be
            represented by or through such individual or entity, as well as its
            and their individual employees, agents and representatives, and/or
            any others who may obtain access to the Platform through such
            individual or entity.
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        Please also note that a “General User” means any User who accesses any
        portion of the Platform for which registration is not required (a
        “Public Portion”), including any User who accesses the Platform through
        the use of crawlers, robots, browsers, data mining or extraction tools,
        or similar functionality, whether such functionality is installed or
        configured by such person or entity or by a third party. A “Registered
        User” means, with respect to any portion of the Platform for which
        registration is required (a “Non-Public Portion”), any individual who
        (1) possesses a Company-issued, currently-valid username and password
        for such Non-Public Portion, and (2) is currently authorized by Company
        to access and use such Non-Public Portion.
      </p>
      <br />
      <p dir="ltr">
        Updates to these Terms of Use. Company may, from time to time, amend
        these Terms of Use by notifying you of such amendments by any reasonable
        means, including by posting the amended terms on the Platform, and your
        access to (including any use of) the Platform following any such
        amendment will constitute your further agreement to follow and be bound
        by these Terms of Use as amended. Any such amendment will not apply to
        any dispute between you and Company arising prior to the date on which
        Company posted such amendment, or otherwise notified you of such
        amendment. The date that these Terms of Use were last amended appears at
        the top of this page.
      </p>
      <br />
      <p dir="ltr">
        Updates to the Platform. Company may, from time to time, with or without
        notice, change, modify, add or delete any content, style, functionality,
        service, accessibility, appearance or use of the Platform or any feature
        thereof; charge, modify or waive any fees (including any fee model)
        required to use the Platform; offer opportunities to some or all Users;
        or shut down the Platform entirely.
      </p>
      <br />
      <p dir="ltr">
        In addition to the foregoing, these Terms of Use contain four parts:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Part 1 applies to all Users.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Part 2 contains additional terms that apply to all Registered Users.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Part 3 contains additional terms that apply to all Users of the
            “Link Your Song” Application.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Part 4 contains additional terms that apply to all Users of
            Songfile® or eSynch®.
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        These Terms of Use also hereby incorporate any additional terms and
        conditions posted by Company through the Platform, or otherwise made
        available to you by Company.
      </p>
      <br />
      <p dir="ltr">
        PART 1: GENERAL TERMS FOR ALL USERS
      </p>
      <br />
      <p dir="ltr">
        This Part 1 applies to all Users.
      </p>
      <br />
      <p dir="ltr">
        <strong>A. License; Limitations.</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Limited License to Use the Platform. Subject to these Terms of Use
            and your full compliance therewith, Company grants to you a
            non-exclusive, nontransferable, revocable, non-sublicensable,
            limited license (the “License”) to access and use the Platform. You
            may use the Platform solely as provided in these Terms of Use, and
            you may use any Databases, Associated Software and Materials solely
            to the extent necessary for your authorized use of the remainder of
            the Platform, or as expressly authorized in writing by Company. You
            acknowledge that you do not acquire any ownership or intellectual
            property rights by using the Platform. Company may at any time
            terminate or limit the License, and/or terminate or limit your use
            of any or all of the Platform, including for any breach of these
            Terms of Use, with or without notice.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Limitations on Use of the Platform. You may not copy, reproduce,
            republish, sell, upload, download, extract, reverse engineer,
            modify, reformat, reconfigure, extract, post, transmit,
            systematically access and store, create any derivative work of, or
            distribute the Platform, or any portion thereof, without the express
            prior written permission of Company. You may not circumvent the
            navigation structure or presentation of the Platform, or use any
            data mining, robots or intelligent agents, or other data gathering
            and extraction tools, in connection with the Platform. You may not
            use any Databases, Associated Software or Materials on any other
            website or on any networked computer environment. You may not frame
            or utilize framing technology to enclose any trademark, logo, or
            other proprietary information (including images, text, page layout,
            or form) of Company. You may not use the Platform for any
            fraudulent, tortious or unlawful purpose. Except as expressly
            provided herein, you may not restrict or inhibit any other visitor
            from using any aspect of the Platform, including by means of
            “hacking” or defacing any portion of the same. Without limiting the
            foregoing, any use of the Platform for purposes other than those
            described in and contemplated by these Terms of Use is strictly
            prohibited. The License set forth above to use Associated Software
            is conditioned upon your acceptance of and compliance with any
            additional terms and conditions that may apply, including any
            applicable license agreements with third parties.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No Right or License in Musical Compositions or Sound Recordings. The
            limited License granted to you by these Terms of Use shall in no way
            be deemed to grant to you any right or license in or to any musical
            composition, including any musical composition available for
            licensing through Company or the Platform. Certain licenses for the
            use of musical compositions represented by Company may be requested
            through other services and applications offered by Company, in
            accordance with these Terms of Use and the procedures, terms and
            conditions disclosed by us in connection with and governing the
            applicable licensing facility. No license request or other
            communication received through the Platform shall result in a valid
            or enforceable license except according to the separate, applicable
            terms and conditions prescribed by Company. Any modification by you
            of any license form or other terms or conditions provided by Company
            will render the applicable license invalid.
          </p>
        </li>
      </ol>
      <br />
      <p dir="ltr">
        <strong
          >B. ACCESS AND AUTHORITY TO USE NON-PUBLIC PORTIONS OF THE
          PLATFORM.</strong
        >
        YOU ARE NOT AUTHORIZED TO ACCESS OR USE ANY NON-PUBLIC PORTION OF THE
        PLATFORM UNLESS YOU ARE A REGISTERED USER OF SUCH PORTION OF THE
        PLATFORM. ANY ATTEMPT BY A NON-REGISTERED USER TO ACCESS OR USE
        NON-PUBLIC PORTIONS OF THE PLATFORM IS A MATERIAL BREACH OF THESE TERMS
        OF USE, AND MAY RESULT IN PENALTIES, INCLUDING IMMEDIATE TERMINATION OF
        THE LICENSE, AND/OR CIVIL OR CRIMINAL PENALTIES.
      </p>
      <br />
      <p dir="ltr">
        <strong>C. Privacy Policy.</strong> Your submission of information
        through the Platform is governed by our
        <router-link :to="{ name: 'privacy-policy' }"
          >Privacy Policy</router-link
        >.
      </p>
      <br />
      <p dir="ltr">
        <strong>D. Use of Applets.</strong> Use of certain applications and
        functionalities available through the Platform may require the download
        or other transfer onto your computer of software “applets” (e.g., small,
        cross-platform compatible pieces of executing code) such as to enable
        communication between your computer and Company’s computers, and/or to
        execute such applications or functionalities. You agree to the transfer
        or download of such applets onto your computer system for the purpose of
        allowing you to access and use of any such applications and
        functionalities available through the Platform.
      </p>
      <br />
      <p dir="ltr">
        <strong>E. Intellectual Property; Proprietary Rights.</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            General. The Platform contains copyrighted and proprietary subject
            matter. Your rights with respect to your use of the Platform are
            governed by all the terms and conditions of these Terms of Use,
            including the limited License granted hereunder, as well as all
            applicable laws, including intellectual property laws.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Copyright. The Platform (including the compilation, selection,
            coordination, arrangement and enhancement of all Databases,
            Associated Software and Materials on the Platform) is the property
            of Company, its suppliers and/or its licensors, and is protected by
            U.S. and international copyright laws. You acknowledge that all such
            rights are valid and enforceable. You may not remove or obscure any
            copyright or other proprietary notices contained on the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Trademark. You are not permitted to use any trade names, trademarks,
            service marks, trade dress and related logos of Company and its
            affiliated legal entities and licensors (collectively, the “Marks”)
            unless you first receive prior written consent to do so from Company
            or the third party that may own such Marks.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Patent. The Platform and/or portions thereof may be protected under
            patent law, and may be the subject of issued patents and/or pending
            patent applications.
          </p>
        </li>
      </ol>
      <br />
      <p dir="ltr">
        <strong
          >F. Digital Millennium Copyright Act — Notification of Alleged
          Copyright Infringement.</strong
        >
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Company has registered an agent with the United States Copyright
            Office in accordance with the terms of the Digital Millennium
            Copyright Act (the “DMCA”), and reserves the right to avail itself
            of the protections under the DMCA. Company reserves the right to
            remove any content from the Platform, including any content alleged
            to infringe any third-party copyright. In appropriate circumstances,
            Company may terminate the access of Users who are copyright
            infringers. If you believe in good faith that any of the Materials
            hosted by Company on the Platform infringe your copyright, you (or
            your agent) may send to us written notice requesting that the
            allegedly infringing material be removed, or access to it be
            blocked.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If you believe in good faith that a notice of copyright infringement
            has been wrongly filed with Company against you, the DMCA permits
            you to send to Company a counter-notice. Notices and counter-notices
            must meet the then-current statutory requirements imposed by the
            DMCA. (See 17 U.S.C § 512(c)(3) for details). Additional information
            regarding the DMCA, including the statutory language and related
            regulations, may be obtained from the United States Copyright
            Office,<a href="http://www.copyright.gov" target="_blank"
              >www.copyright.gov</a
            >.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Notices and counter-notices with respect to the Platform must be in
            writing and directed by email to DMCA@HarryFox.com and by regular
            mail to General Counsel/Copyright Agent, The Harry Fox Agency LLC,
            40 Wall Street, 6th floor, New York, NY 10005, Attn: DMCA Notice.
            You may leave a message for our agent by telephone at 212-834-0102.
            We suggest that you consult your legal advisor before serving a
            notice or counter-notice. Also, be aware that there can be penalties
            for false claims under the DMCA.
          </p>
        </li>
      </ol>
      <br />
      <p>
        <strong>G. Purchasing Services.</strong> Certain services made available
        through the Platform may require payment by credit card. If you wish to
        purchase such services, you will be asked by Company to supply certain
        information applicable to your purchase, including credit card and other
        information. You understand that any such information will be treated by
        Company in the manner described in its Privacy Policy. You agree that
        all information that you provide to Company will be accurate, current
        and complete. You agree to pay all charges incurred by you or any users
        of your account and credit card (or other applicable payment mechanism)
        at the price(s) in effect when such charges are incurred. You will also
        be responsible for paying any applicable taxes relating to your
        purchases. (For additional information regarding pricing, see Legal
        Notices Under California Law — Pricing Information below.)
      </p>
      <br />
      <p dir="ltr">
        <strong>H. Third Parties</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Third-Party Products and Services. From time to time, Company may
            have the opportunity to offer to you products and services from
            third-party vendors, including at discounted prices and through
            special arrangements. Please be aware, however, that Company cannot
            take any responsibility for such third-party products and services.
            Please address any inquiries or concerns you may have about such
            products and services directly to the relevant vendor. Also, please
            note that, in some cases, Company may receive a referral fee or
            other consideration from the vendor for making the goods and
            services available to you. By taking advantage of the offer made
            available through Company, you are agreeing to any such fee or other
            arrangement.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Third-Party Online Resources. The Platform may contain links to
            websites and other online resources of third parties that are not
            under Company’s control. Company makes no representations of any
            kind regarding the content of such third-party online resources, and
            you hereby irrevocably waive any claim against Company with respect
            to such online resources. You agree that any time you access a
            third-party online resource that is linked through the Platform, you
            do so at your own risk. Company is not responsible for the accuracy
            or reliability of any information or materials contained on any
            third-party online resources. Company may make available links to
            other online resources merely as a convenience to you. Any comments
            regarding any third-party online resources should be directed to the
            entity whose website contains these materials or resources.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            <strong>DISCLAIMER.</strong> YOUR USE OF THIRD-PARTY WEBSITES AND
            OTHER THIRD-PARTY MATERIALS AND RESOURCES IS AT YOUR OWN RISK AND IS
            SUBJECT TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE
            TO SUCH THIRD-PARTY WEBSITES, MATERIALS AND RESOURCES (SUCH AS TERMS
            OF SERVICE OR PRIVACY POLICIES OF THE PROVIDERS OF SUCH THIRD-PARTY
            WEBSITES, MATERIALS AND RESOURCES).
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Linking to the Platform by Third Parties. A third-party website that
            links or seeks to link to the Platform: (1) may link to the home
            page of the Platform (or any other page that Company may designate
            from time to time), but not replicate any of the Materials; (2) may
            not create a browser, border or frame environment around the
            Platform’s content; (3) may not imply that Company is endorsing it
            or its products; (4) may not misrepresent its relationship with
            Company, its publishers or licensees; (5) may not use any Company
            logo or other proprietary graphic or trademark as part of any link
            or otherwise without Company’s express prior written permission; and
            (6) may not portray Company, its related legal entities, directors,
            officers, employees, publishers or licensees or their services in a
            false, misleading, derogatory, or otherwise offensive manner. A
            third-party website seeking to link to the Platform must not violate
            any laws, advocate unlawful activity or contain content that is not
            appropriate for all ages. Company expressly reserves the right to
            remove any links to the Platform or request that you remove any
            links to the Platform that are not in compliance with these Terms.
          </p>
        </li>
      </ol>
      <br />
      <p dir="ltr">
        <strong>I. DISCLAIMER OF WARRANTIES.</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            <strong>GENERAL DISCLAIMERS.</strong> COMPANY PROVIDES THE PLATFORM
            AND THE MATERIALS “AS IS” AND “AS AVAILABLE” AND WITHOUT ANY
            WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. COMPANY
            SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
            ACCURACY, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING,
            COURSE OF PERFORMANCE, OR USAGE OF TRADE. COMPANY DOES NOT WARRANT
            THAT YOUR USE OF THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE, OR
            SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM OR THE
            SERVER(S) ON WHICH THE PLATFORM IS HOSTED ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE
            FOR OBTAINING AND MAINTAINING ALL TELEPHONE, CABLE, COMPUTER
            HARDWARE, AND OTHER EQUIPMENT NEEDED TO ACCESS AND USE THE PLATFORM,
            AND ALL CHARGES RELATED THERETO. YOU ASSUME ALL RESPONSIBILITY AND
            RISK FOR YOUR USE OF THE PLATFORM AND YOUR RELIANCE THEREON.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Although we attempt to ensure the integrity of the Platform, we make
            no guarantees as to its completeness or correctness. In the event
            that a situation arises in which the Platform’s completeness or
            correctness is in question, please contact Company by mail at the
            address provided above or via Company’s
            <a @click="showContactForm">Contact Form</a> and, if possible,
            provide a description of the material to be checked and the specific
            location (URL) where such material can be found on the Platform, as
            well as information sufficient to enable us to contact you. We will
            try to address your concerns as soon as reasonably practicable. For
            copyright infringement claims, see Digital Millennium Copyright Act
            — Notification of Alleged Copyright Infringement above.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Company hopes that the Materials will be helpful as a background
            reference, but they should not be construed as legal, accounting, or
            other professional advice on any subject matter. In addition,
            Company strives to provide accurate information, but Company is not
            engaged in providing legal, accounting, or like professional
            services, and availability or use of the Materials is not intended
            to create, and does not create, any such professional services
            relationship. Use of the Materials is not an adequate substitute for
            obtaining legal, accounting, or other professional advice from a
            licensed provider in your jurisdiction. You agree you will not act
            or refrain from acting based on any of the Materials without first
            seeking the services of a competent professional.
          </p>
        </li>
      </ol>
      <br />
      <p>
        <strong>J. DISCLAIMERS REGARDING THE DATABASES.</strong> DATA CONTAINED
        IN THE DATABASES HAS BEEN PROVIDED TO COMPANY BY COPYRIGHT OWNERS,
        LICENSEES AND OTHERS. COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS
        WHATSOEVER WITH RESPECT TO THE ACCURACY OR COMPLETENESS OF THE DATA MADE
        AVAILABLE THROUGH THE PLATFORM. COMPANY SPECIFICALLY DISCLAIMS ANY AND
        ALL LIABILITY ON THE PART OF ANY COVERED PARTY (AS DEFINED BELOW) FOR
        ANY LOSS OR DAMAGES WHICH MAY BE INCURRED, DIRECTLY OR INDIRECTLY, AS A
        RESULT OF THE USE OF THE INFORMATION IN THE DATABASES, OR FOR ANY
        OMISSIONS OR ERRORS CONTAINED IN THE DATABASES.
      </p>
      <p>
        In some cases, the copyright ownership information shown for a musical
        composition or sound recording may not reflect actual copyright
        ownership of such musical composition or sound recording, or may no
        longer be current. Musical compositions or sound recordings listed in a
        Database may not be fully represented or represented at all by Company,
        and may not be licensable through Company. The data contained in online
        licensing reports and statements may no longer be current at the time
        such data is accessed through the Platform.
      </p>
      <br />
      <p dir="ltr">
        <strong>K. LIMITATION OF LIABILITY.</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            A COVERED PARTY (AS DEFINED BELOW) SHALL NOT BE LIABLE FOR ANY LOSS,
            INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING IN ANY WAY
            FROM (1) ANY ERRORS OR OMISSIONS IN, OR THE CONTENT OF, THE
            PLATFORM; (2) THE UNAVAILABILITY OF THE PLATFORM, INCLUDING ANY
            FEATURES THEREOF; (3) ANY DAMAGE TO A COMPUTER SYSTEM OR LOSS OF
            DATA IN CONNECTION WITH THE USE OF THE PLATFORM; (4) ANY
            UNAUTHORIZED TRANSACTION OR MISUSE OF THE PLATFORM OR YOUR DATA BY
            AN EMPLOYEE OF CUSTOMER OR BY ANY OTHER PARTY; AND/OR (5) ANY LOSS
            OR MISUSE OF A USERNAME OR PASSWORD.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            “Covered Party” means (1) Company, its related legal entities, and
            any officer, director, employee, subcontractor, agent,
            representative, successor or assign of Company or its related legal
            entities; and (2) each third-party supplier of any portion of the
            Platform, their affiliates, and any officer, director, employee,
            subcontractor, agent, representative, successor or assign of any
            third-party supplier of materials or any of their affiliates.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            IN ADDITION TO THE MORE SPECIFIC LIMITATIONS OF ALL LIABILITY SET
            FORTH ABOVE, NO COVERED PARTY WILL BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES
            OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
            LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS,
            LOSS OF USE, LOSS OR USE OF DATA, LOSS OF OTHER INTANGIBLES, LOSS OF
            SECURITY OF SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY
            THIRD PARTIES OF ANY SUBMISSIONS), EVEN IF ADVISED IN ADVANCE OF THE
            POSSIBILITY OF SUCH DAMAGES OR LOSSES. WITHOUT LIMITING THE
            FOREGOING, NO COVERED PARTY WILL BE LIABLE FOR DAMAGES OF ANY KIND
            RESULTING FROM YOUR USE OF OR INABILITY TO USE THE PLATFORM OR FROM
            ANY SERVICES OR THIRD PARTY MATERIALS. YOUR SOLE AND EXCLUSIVE
            REMEDY FOR DISSATISFACTION WITH THE PLATFORM OR ANY SERVICES OR
            THIRD PARTY MATERIALS IS TO STOP USING THE PLATFORM. THE MAXIMUM
            AGGREGATE LIABILITY OF ALL COVERED PARTIES COLLECTIVELY, FOR ALL
            DAMAGES, LOSSES, AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT
            (INCLUDING NEGLIGENCE), OR OTHERWISE, SHALL BE TEN U.S. DOLLARS
            ($10.00). ALL LIMITATIONS OF LIABILITY OF ANY KIND (INCLUDING IN
            THIS SECTION AND ELSEWHERE IN THESE TERMS OF USE) ARE MADE ON BEHALF
            OF BOTH COMPANY AND THE COVERED PARTIES.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Applicable law may not allow for limitations on certain implied
            warranties, or exclusions or limitations of certain damages; solely
            to the extent that such law applies to you, some or all of the above
            disclaimers, exclusions or limitations may not apply to you, and you
            may have certain additional rights.
          </p>
        </li>
      </ol>
      <br />
      <p>
        <strong>L. RELEASE OF LIABILITY.</strong> COMPANY ACTS MERELY AS AN
        INTERMEDIARY IN THE TRANSACTION PROCESS OR AN INTERMEDIARY ON BEHALF OF
        DISCLOSED COPYRIGHT OWNERS AS AN AGENT, AND IS NOT A PRINCIPAL IN ANY
        TRANSACTION BETWEEN A COPYRIGHT OWNER AND LICENSEE OR BETWEEN USERS AND
        THIRD PARTIES MAKING OFFERS AVAILABLE THROUGH THE PLATFORM. WHERE
        COMPANY HAS ACTED AS AN INTERMEDIARY, YOU HEREBY RELEASE COMPANY, ITS
        RELATED LEGAL ENTITIES, AND ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES,
        SUBCONTRACTORS, AGENTS, SUCCESSORS AND ASSIGNS FROM ANY CLAIM, DEMAND,
        DAMAGE (ACTUAL, CONSEQUENTIAL, SPECIAL, OR OTHER), OR LIABILITY
        (VICARIOUS OR OTHERWISE) OF ANY KIND OR NATURE, KNOWN AND UNKNOWN,
        SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR
        IN ANY WAY CONNECTED WITH THE ACTIONS OF ANY COPYRIGHT OWNER, LICENSEE,
        USER, OR THIRD PARTY THAT MAKES OFFERS AVAILABLE THROUGH THE PLATFORM,
        OR ANY DISPUTE BETWEEN OR AMONG ONE OR MORE OF SUCH USERS, INDIVIDUALS,
        OR ENTITIES.
      </p>
      <p>
        TO THE EXTENT IT MAY APPLY, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE §
        1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
        CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
        TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
      </p>
      <br />
      <p>
        <strong>M. Indemnification.</strong> Except to the extent prohibited
        under applicable law, you agree to defend, indemnify and hold harmless
        any Covered Party from and against all claims, liabilities, damages,
        judgments, awards, losses, costs, expenses and fees (including
        attorneys’ fees) arising from, relating to, or resulting from (a) your
        breach or alleged breach of these Terms of Use, including any covenants,
        representations and warranties therein; (b) your access to, use, misuse,
        or alleged misuse of the Platform; or (c) your violation or alleged
        violation of any law or any rights of any third party.
      </p>
      <br />
      <p dir="ltr">
        <strong>N. Legal Notices Under California Law.</strong> Under California
        Civil Code Section § 1789.3, California residents are entitled to the
        following specific consumer rights information:
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Pricing Information. Current fees for our services may be obtained
            by contacting Company at the mailing address provided above or via
            Company’s <a @click="showContactForm">Contact Form</a>. Company
            reserves the right to change its fees or to institute new fees at
            any time.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Complaints. The Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            may be contacted in writing at Department of Consumer Affairs,
            Consumer Information Division, 1625 North Market Blvd., Suite N 112,
            Sacramento, CA 95834, or by telephone at 916-445-1254 or
            800-952-5210.
          </p>
        </li>
      </ol>
      <br />
      <p>
        <strong>O. Filtering.</strong> We hereby notify you that parental
        control protections (such as computer hardware, software or filtering
        services) are commercially available that may assist you in limiting
        access to material that is harmful to minors. Company is not responsible
        for implementing any parental control protections.
      </p>
      <br />
      <p>
        <strong>P. Miscellaneous.</strong> If any provision of these Terms of
        Use is held to be invalid or unenforceable, such provision shall be
        modified so that it is valid and enforceable while reflecting to the
        greatest extent possible the original intent of the parties, and the
        remaining provisions shall be enforced pursuant to these Terms of Use.
        You agree that these Terms of Use and all agreements incorporated herein
        may be assigned by Company, in its sole discretion, to a third party.
        You may not assign these Terms of Use or any agreement incorporated
        herein without the prior written consent of Company. Headings are for
        reference purposes only and in no way define, limit, construe, or
        describe the scope or extent of the text to which they pertain. Any use
        of the term “including” or variations thereof in these Terms of Use
        shall be construed as if followed by the phrase “without limitation.”
        Company’s failure to act with respect to a breach by you or others does
        not waive Company’s right to act with respect to subsequent or similar
        breaches. These Terms of Use, and any additional terms or conditions set
        forth by Company in connection with specific services or features made
        available through the Platform, which are incorporated herein by
        reference, collectively set forth the entire understanding between you
        and Company with respect to the subject matter hereof and thereof.
        Notwithstanding the foregoing, if you and Company have entered or enter
        into a separate written agreement with respect to any such specific
        services or features, then, solely with respect to such services or
        features, such separate agreement will control in the event of any
        irreconcilable conflict between the terms of such separate agreement and
        the terms of these Terms of Use. Notices to you (including notices of
        changes to these Terms of Use) may be made via posting to the Platform
        or by e-mail (including in each case via links), or by regular mail.
        Without limitation, a printed version of these Terms of Use and of any
        notice given in electronic form shall be admissible in judicial or
        administrative proceedings based upon or relating to these Terms of Use
        to the same extent and subject to the same conditions as other business
        documents and records originally generated and maintained in printed
        form.
      </p>
      <br />
      <p>
        <strong>Q. Jurisdictional Issues.</strong> The Platform is controlled or
        operated (or both) from the United States, and is not intended to
        subject Company or any of its related legal entities, officers,
        directors, employees, subcontractors, agents, representatives,
        successors or assigns to the laws or jurisdiction of any state, country
        or territory other than the United States. Company does not represent or
        warrant that the Platform or any specific features or services that may
        be accessible through the Platform are appropriate or available for use
        in any particular jurisdiction. Those who choose to access the Platform
        and the entities that such individuals represent do so on their own
        initiative and at their own risk, and are responsible for complying with
        local laws, if and to the extent local laws are applicable. Company may
        limit the availability of the Platform and/or specific features or
        services of the Platform with respect to any person, geographic area or
        jurisdiction we choose, at any time, in our sole discretion.
      </p>
      <br />
      <p>
        <strong>R. Applicable Law; Arbitration.</strong> The laws of the United
        States (including federal arbitration law) and the State of New York,
        without regard to principles of conflict of laws, will govern these
        Terms of Use, and any dispute of any sort that might arise between you
        (including the entity you represent) and/or Company and/or any User or
        third party making an offer(s) through the Platform arising from or
        related to these Terms of Use. ALL DISPUTES ARISING OUT OF OR RELATED TO
        THESE TERMS OF USE OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND
        COMPANY, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD,
        MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH
        FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN
        A COURT BY A JUDGE OR JURY, AND YOU AGREE THAT YOU AND COMPANY ARE EACH
        WAIVING THE RIGHT TO TRIAL BY A JURY. YOU AGREE THAT ANY ARBITRATION
        UNDER THESE TERMS OF USE WILL TAKE PLACE SOLELY BETWEEN YOU AND COMPANY,
        AND NOT TOGETHER WITH OR ON BEHALF OF ANY OTHER PARTIES; CLASS
        ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO
        GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION. The arbitration
        will be administered by the American Arbitration Association under its
        Commercial Arbitration Rules, as amended by these Terms of Use. The
        Commercial Arbitration Rules are available online at
        <a
          href="https://www.adr.org/aaa/ShowProperty?nodeId=/UCM/ADRSTG_004103&revision=latestreleased"
          target="_blank"
          >https://www.adr.org/aaa/ShowProperty?nodeId=/UCM/ADRSTG_004103&revision=latestreleased</a
        >. Any in-person appearances will be held in New York County, New York
        State. The arbitrator’s decision will follow the terms of these Terms of
        Use and will be final and binding. The arbitrator will have authority to
        award temporary, interim or permanent injunctive relief or relief
        providing for specific performance of these Terms of Use, but only to
        the extent necessary to provide relief warranted by the individual claim
        before the arbitrator. The award rendered by the arbitrator may be
        confirmed and enforced in any court having jurisdiction thereof.
      </p>
      <br />
      <p>
        <strong>S. Export Control Laws.</strong> You are responsible for
        complying with United States export controls and for any violation of
        such controls, including any United States embargoes or other federal
        rules and regulations restricting exports. You represent, warrant and
        covenant that you are not (a) located in, or a resident or a national
        of, any country subject to a U.S. government embargo or other
        restriction, or that has been designated by the U.S. government as a
        “terrorist supporting” country; (b) on any of the U.S. government lists
        of restricted end users.
      </p>
      <br />
      <p>
        PART 2: SUPPLEMENTAL TERMS FOR ALL REGISTERED USERS
      </p>
      <br />
      <p>
        In addition to all other applicable provisions of these Terms of Use,
        this Part 2 sets forth additional terms that apply to all Registered
        Users.
      </p>
      <br />
      <p dir="ltr">
        <strong
          >A. Access and Authority to Use Non-Public Portions of the
          Platform.</strong
        >
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Access. You may not access or use the Non-Public Portions unless you
            are a Registered User of the applicable Non-Public Portion. Your
            username and additional identifying information, including whether
            you or the entity you represent are a publisher, songwriter,
            copyright owner, or licensee, will govern which specific features of
            and information available through the Non-Public Portions you will
            be permitted to access and use, and you will comply with all
            applicable restrictions on your access to the Non-Public Portions
            imposed by Company from time to time. Certain individuals will be
            designated as Administrators (as defined below), and will be
            responsible for overseeing access of individuals within their
            entities to the Non-Public Portions. Please review Administrator
            Responsibilities below for additional information about the
            responsibilities of Administrators. The Non-Public Portions, like
            other areas of the Platform, are not intended for, and are not to be
            accessed by, minors.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Your Account, Username and Password. You agree to use your username,
            password, and access to the Non-Public Portions only to conduct
            business on behalf of yourself (including, if applicable, the entity
            you represent). You further agree that you will not transmit,
            transfer, assign, or sell your username or password to any other
            person, even within your entity, or permit the use thereof by anyone
            else under any circumstance. Company will not be liable in the event
            of the loss of a username or password or the misuse by anyone of a
            username or password. It is your sole responsibility to secure and
            maintain your username and password and to keep current any personal
            or business information provided to Company. You agree that you will
            (1) log out and close down access to the Non-Public Portions at the
            end of each Non-Public Portions session; and (2) immediately notify
            your Administrator(s) (as defined below) of your loss, or any
            unauthorized use of, your username and/or password or account, or
            any other breach of security. You have the option to change your
            password as often as you wish by creating a new password. It is
            recommended that you change your password at least once every 60
            days.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Deactivation of Registered Users. Each of Company and your
            Administrator(s) has the right to deactivate or suspend you (or
            restrict your access rights) as a Registered User at any time for
            any reason, including for the reason that (1) you are terminating
            your employment with your entity; (2) your Registered User account
            will be inactive for an extended period of time; or (3) there is
            reason to believe that you have violated or will violate these
            Terms. Upon any deactivation of your account by Company or your
            Administrator(s), your License to the Non-Public Portions of the
            Platform will automatically terminate and you agree that you will
            cease use of the Non-Public Portions immediately.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Administrator Responsibilities. Each User has designated or may
            designate at least one authorized individual to serve as its
            administrator for purposes of overseeing access of the User’s
            authorized representatives to the Platform (“Administrator”). The
            Administrator will be responsible for creating and maintaining
            Registered User accounts, including distributing usernames and
            initial passwords to each Registered User. The Administrator will
            have access to the complete range of features and services available
            through the Non-Public Portions to Users, as applicable. The
            Administrator shall determine which features and services each
            Registered User may access by selecting and assigning one or more
            group-level permissions (“Groups”) (descriptions of which are
            available to Administrators through the Non-Public Portions) to each
            Registered User based on each such Registered User’s need and
            appropriateness to access specific Non-Public Portions features or
            services. Each Administrator is responsible for overseeing and
            monitoring access by its Registered Users to the Non-Public
            Portions. If you are an Administrator, you must deactivate
            Registered User accounts when Registered Users no longer require
            access to the Non-Public Portions for any reason, including because
            they are no longer employed by your entity, are on an extended leave
            of absence, or have violated these Terms. If you have reason to
            believe that a password is being misused by a Register must
            deactivate the account and notify Company immediately by email to
            the address provided to you when you registered for an account or by
            submitting an inquiry on Company’s
            <a @click="showContactForm">Contact Form</a>. The Administrator(s),
            and the Users they represent, are solely responsible for the conduct
            of the individuals they authorize to access the Non-Public Portions
            on behalf of their entity. Although Company is not responsible for
            monitoring access to the Non-Public Portions by an entity’s
            Registered Users, Company reserves the right to deactivate, suspend
            or restrict any Registered User’s access to the Non-Public Portions
            at any time.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Primary Administrator; Changing or Adding Administrators. The person
            initially designated to act as Administrator on behalf of an entity
            (“Primary Administrator”) has the authority to designate one or more
            additional Administrators for their entity, who will have the same
            privileges and responsibilities as the Primary Administrator and who
            can, in turn, designate additional Administrators. The Primary
            Administrator must ensure that all Administrators agree to be bound
            by these Terms, including the provisions specifically applicable to
            Administrators. If there is any change with the Primary
            Administrator, Users are obligated to notify Company immediately by
            email to the address provided to you when you registered for an
            account or by submitting an inquiry on Company’s
            <a @click="showContactForm">Contact Form</a>.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Authority to Transact Business Through the Non-Public Portions. Each
            User, acting through its Administrator, by providing a username and
            password to an employee, representative or any other individual
            (including the Administrator) (1) represents and warrants that each
            person to whom it assigns to a Group with permission to request,
            approve or accept licenses or license terms, or to conduct other
            transactions on the entity’s behalf, has authority to enter into
            binding agreements and licenses on behalf of such User; (2)
            represents and warrants that each person whom it assigns to a Group
            with permission to access or modify data involving such User has
            authority to obtain, review and/or modify such data; and (3) assumes
            full responsibility for all activities of such person in connection
            with the Non-Public Portions.
          </p>
        </li>
      </ol>
      <br />
      <p>
        <strong
          >B. Electronic Delivery of Notices of Intention to Obtain a Compulsory
          License and Monthly and Annual Statements of Account.</strong
        >
        By requesting and accepting access to the Non-Public Portions (including
        by logging on at least one time to the Non-Public Portions), each
        Registered User (individually and on behalf of any individual or entity
        the Registered User represents) authorizes Company to deliver, and
        agrees to receive, any Notices of Intention to Obtain a Compulsory
        License (“NOIs”) under Section 115 of the Copyright Act, 17 U.S.C. §
        115, by way of electronic delivery, including by way of email or by way
        of posting to your online account. A Registered User may withdraw its
        consent for electronic delivery of NOIs by emailing
        <a href="mailto:clientservices@harryfox.com"
          >clientservices@harryfox.com</a
        >. Such withdrawal will be effective within thirty (30) days of receipt
        of the request. In addition, each Registered User (individually and on
        behalf of any individual or entity the Registered User represents)
        authorizes Company to deliver, and agrees to receive, any monthly
        statements of account and annual statements of account by way of
        electronic delivery, including by way of email or by way of posting to
        your online account.
      </p>
      <br />
      <p>
        <strong
          >C. Electronic Communication and Transaction of Business.</strong
        >
        By requesting and accepting access to the Non-Public Portions (including
        by logging on at least one time to the Non-Public Portions), you consent
        to receive communications from Company electronically and to have
        Company communicate with you by email or by posting notices on the
        Non-Public Portions (subject to the Privacy Policy). You agree that all
        agreements, licenses, notices, disclosures and other documents and
        communications that Company provides to you electronically satisfy any
        legal requirement that such documents or communications be in writing,
        and that such documents and communications are of the same legal effect
        as though they had been provided in non-electronic (hard copy) form. You
        further agree that, to the extent contemplated and permitted by the
        Non-Public Portions, you and/or other users of the Non-Public Portions
        with which you are transacting business (including other Users and
        Company) may review, accept and enter into binding agreements, including
        licenses, by electronic means without the requirement of a physical
        signature.
      </p>
      <br />
      <p>
        <strong>D. Submissions and Third-Party Content.</strong>
        The Non-Public Portions may provide Users with certain functionalities
        to upload or transmit certain materials and information, including
        personal identifying information, to the Non-Public Portions, in
        connection with licensing and other activities conducted through the
        Non-Public Portions. In addition, you may need to provide certain
        personal identifying information in order to benefit from the services
        provided by Company. Subject to the terms of the Privacy Policy, you
        acknowledge and agree that Company may use, publicly display, and/or
        share such materials and information as appropriate in order to
        facilitate the conduct of business and provision of services through the
        Non-Public Portions and Company. You agree not to upload or transmit to
        the Non-Public Portions any content which (1) is libelous, defamatory,
        invasive of privacy, obscene, pornographic, abusive or threatening (it
        is, however, permissible to upload and reference bona fide names of
        songs, albums and artists); (2) violates or infringes any person’s legal
        rights or the legal rights of any entity, including copyrights,
        trademarks, or trade secrets; (3) violates any law; (4) advocates any
        unlawful activity; (5) solicits funds, or otherwise advocates, for any
        entity, goods, or services; (6) contains any viruses, worms, Trojan
        horses, defects, time bombs, or other items of a harmful nature; (7) is
        inaccurate or misleading; or (8) implies or suggests that such content
        is endorsed by Company. You are solely responsible for any content you
        submit, and Company has no responsibility for any such content,
        including its legality, originality, reliability and/or appropriateness.
        You acknowledge and agree that Company has the right (but not the
        obligation) to monitor any submissions or materials transmitted through
        the Non-Public Portions, and to alter or remove any such materials, to
        disclose such materials to any third party in order to operate the
        Non-Public Portions properly and to comply with legal obligations or
        governmental requests.
      </p>
      <br />
      <p>
        <strong
          >E. Receipt of Information from You and Collection of Information from
          Third Parties.</strong
        >
        In order for Company to continue to provide services to you, it is
        necessary for Company to maintain correct and up-to-date information
        concerning you. You may continue to submit updated information to
        Company directly. To the extent you provide updated information to a
        third-party client of Company, you permit Company to obtain that
        information from such third-party client and permit Company to use that
        updated information to continue to communicate opportunities to you,
        distribute royalties, if any, and provide other services, in accordance
        with Company’s Privacy Policy.
      </p>
      <br />
      <p>
        <strong>F. Changes to Licenses.</strong> From time to time, a license
        requested to be issued or issued through the Platform may be updated or
        amended as a result of changes in the respective licensing interests
        and/or Company representation. In addition, Company reserves the right
        to amend or void a license in the event that Company determines such
        license to have been improperly issued, issued on the basis of a false,
        misleading or otherwise improper application and/or issued to a party
        not eligible to obtain licenses through Company.
      </p>
      <br />
      <p>
        <strong>G. Changes to Related Entities.</strong> In some cases Company
        may be notified of a change in the particular entities or catalogs owned
        or administered by, or related to, a User. Company reserves the right to
        make appropriate changes to its Databases and ongoing licensing
        practices to reflect such a change in relationship. For example and
        without limitation, in the case where a publisher is acquired or becomes
        administered by another publisher that acts as a “top level” publisher
        (i.e., controlling entity) according to Company’s records, certain
        licensing authorizations of the newly acquired or administered publisher
        may be adjusted to reflect the licensing authorizations of the “top
        level” publisher, including without limitation any “opt-in” or “opt-out”
        authorizations of the “top level” publisher. In addition, if you become
        aware of any changes to any entities or catalogues owned or administered
        by you, you agree to promptly notify Company of such changes in writing.
      </p>
      <br />
      <p>
        PART 3: SUPPLEMENTAL TERMS FOR THE “LINK YOUR SONG” APPLICATION
      </p>
      <br />
      <p>
        In addition to all other applicable provisions of these Terms of Use,
        this Part 3 sets forth additional terms that apply to all Users of Link
        Your Song.
      </p>
      <br />
      <p>
        <strong>A. No Representations or Warranties.</strong> Company seeks to
        use commercially reasonable efforts to suggest potential matches, in
        connection with Link Your Song, between sound recordings and musical
        compositions that you own, in whole or in part. Notwithstanding the
        foregoing, Company makes no representations or warranties as to whether
        there will be any suggested potential matches, or as to the accuracy of
        any suggested potential match. By providing you with a list of potential
        matches, Company does not intend to suggest any actual match. Company
        relies on you to submit accurate information relevant to matches between
        a particular sound recording and a musical composition that you own, in
        whole or in part.
      </p>
      <br />
      <p>
        <strong>B. Certification.</strong> By clicking “CONFIRM” and notifying
        Company that a particular sound recording should be linked to a musical
        composition that you own, in whole or in part, you certify that the
        information you transmit is true and accurate.
      </p>
      <br />
      <p>
        PART 4: SUPPLEMENTAL TERMS FOR SONGFILE® AND ESYNCH®
      </p>
      <br />
      <p>
        In addition to all other applicable provisions of these Terms of Use,
        this Part 4 sets forth additional terms that apply to all Users of
        Songfile and eSynch, including any future versions thereof, and any
        other current or future music licensing operations operated or
        controlled by Company (the “Licensing Platforms”).
      </p>
      <br />
      <p dir="ltr">
        <strong>A. Access and Authority to Use the Licensing Platforms.</strong>
      </p>
      <br />
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Access and Authority to Use the Licensing Platforms. Only those
            Registered Users who have accepted these Terms of Use by clicking on
            an “Agree” button and obtained a currently valid username and
            password to use any of the Licensing Platforms (thus becoming
            “Licensing Platform Users”) may access and use the Licensing
            Platform licensing facilities. As a condition precedent to accessing
            the Licensing Platforms and obtaining licenses through the Licensing
            Platforms, respectively, you represent and warrant that you and any
            entities you purport to represent, including such entities’
            affiliates, (collectively “Your Represented Entities”) are in good
            standing with Company, and that Your Represented Entities’
            privilege(s) of obtaining licenses from Company through other means
            or licensing applications has not been revoked, terminated,
            suspended, restricted, or placed on hold. In the event that any of
            Your Represented Entities are not in good standing with Company or
            Your Represented Entities’ privilege(s) of obtaining licenses from
            Company through other means or licensing applications has been
            revoked, terminated, suspended, or placed on hold, you agree that
            any licenses obtained through the Licensing Platforms are void ab
            initio and of no force or effect.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Deactivation of the Licensing Platform Users. Company has the right
            to deactivate or suspend you (or restrict your access rights) as a
            Licensing Platform User at any time for any reason, including
            without limitation for the reason that Company believes that you
            have violated or will violate these Terms of Use. Upon any
            deactivation of your Licensing Platform privileges by Company, your
            License to use the Licensing Platform licensing facilities will both
            automatically terminate and you agree that you will cease use of the
            Licensing Platforms immediately.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Authority to Transact Business Through the Licensing Platforms. By
            accessing and conducting transactions through the Licensing
            Platforms, each Licensing Platform User is representing and agreeing
            that such Licensing Platform User has the full right and authority
            to engage in such transactions, including without limitation any
            associated credit card transactions, and to enter into binding
            licensing and other agreements, on such Licensing Platform User’s
            own behalf and/or on behalf of any entity such Licensing Platform
            User purports to represent in connection with such transactions, and
            assumes full responsibility for all such transactions and
            agreements. Each Licensing Platform User further represents and
            agrees that such Licensing Platform User will review all license
            terms that will govern the license(s) requested by such Licensing
            Platform User through the Licensing Platforms, as applicable.
            Company may provide, in its sole discretion, a copy of licensing
            terms on the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Licensing Platform Users may have the ability to access streams of
            songs made available on the Licensing Platform, but you may not
            download, copy, forward, or make any other use of a song unless you
            are expressly authorized to do so by Company. Songs streamed and
            downloaded from the Licensing Platforms may only be used for
            testing, presentation, and/or research with a view to possible usage
            by you as specified in your request for a license through the
            Licensing Platform.
          </p>
        </li>
      </ol>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Contact from "@/views/Contact.vue";

export default {
  name: "TermsOfUse",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Terms of Use",
      meta: [
        {
          name: "description",
          content: "HFA Terms of use"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  methods: {
    showContactForm: function() {
      this.$modal.show(
        Contact,
        {},
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  }
};
</script>

<style lang="scss">
#terms-of-use {
  .content {
    margin: 75px 50px 20vh 0;

    .last-updated {
      margin-bottom: 50px;
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>
